import React from 'react';



const Hobby = () => {
  return (
    <div className="min-h-screen mx-4 ">
      <h1><strong>Tech Projects</strong></h1>
      {/* used for youtube video */}
        <div className='flex flex-wrap gap-4'>
            <div >
            <h2><strong>AWS pipeline with Elastic Beanstalk and node.js</strong></h2>
            <iframe width="420" height="345" src="https://www.youtube.com/embed/LRRCWWoKgRo" title='AWS pipeline'></iframe>
            </div>
            <div>
            <h2><strong>Pagination in reactjs</strong></h2>
            <iframe width="420" height="345" className='' src="https://www.youtube.com/embed/Tyd47kyXGTk" title='pagination'></iframe>
            </div>
            <div>
            <h2><strong>Projects on my github</strong></h2>
            <a href="https://github.com/Herbertbhs02" target="_blank">Github link </a>
            </div>
      </div>
    </div>
  );
};

export default Hobby;
