import React from 'react';

import './index.css';


const Homepage = () => {
  return ( 
    <div className="position:relative px-5  text-white bg-black w-full min-h-screen ">
               
        <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-slate-600 w-full mb-2'>
          <h4 className='pt-4 font-bold underline'>PERSONAL STATEMENT</h4>
          <p className='mt-2 border-l-4 border-orange-100 p-4'>
          <img src="/profilePicture4.png"  alt="" className='rounded-full w-28 md:w-60 float-right border-8 border-white' />
          I am currently working as Electronics Obsolescence Engineer for a corporate company, but previously I worked as a electronics Components Engineer 
          and wireless communication
          systems engineer.<br/><br/>
          In addition to my core roles, I have actively pursued a parallel path in web development and hold an AWS Certified Developer Associate and JavaScript certificate in algorithm and data structures, showcasing 
          my commitment to staying abreast of cutting-edge developments in cloud computing technology and web developed. My ability to swiftly acquire new technical skills has been
          a hallmark of my career, enabling me to seamlessly transition into roles beyond my conventional purview.<br/><br/>
          I am characterized by resourcefulness, proactiveness, and a willingness to improvise to achieve objectives. Implementing preventative measures to address
           anticipated challenges is a cornerstone of my approach, ensuring seamless operations and optimal performance. I firmly believe in the power of collaboration
            and teamwork, fostering an environment where shared information and collective efforts are paramount to overcoming challenges.
          </p>
        </div>
          

        <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-slate-600 w-full mb-2'>
          <h4 className='pt-4 font-bold underline'>Job Title: Electronics Obsolescence Engineer</h4>
          <ul className='mt-2  border-l-4 border-orange-200 p-4'>
              <li>
               Conduct a comprehensive annual obsolescence analysis for both internally manufactured parts and third-party
               parts/components utilizing a combination of internal and external tools. Employ the findings to compile detailed 
               obsolescence reports for clients, wherein each project report meticulously outlines the status of every part
                or component, accompanied by recommended mitigation measures.
              </li>
              <li>
              Seeking obsolescence and lifecycle updates from the original manufacturers or suppliers of third-party and components.
              </li>
              <li>
              I engage with account managers, sales representatives, the material team, engineers, and store/logistics team,
              to address critical issues such as stock levels, Last-Time-Buy, End-Of-Life scenarios,alternative replacements, etc.
               This collaborative effort enables us to establish robust processes and implement
              effective mitigation strategies, ensuring the adept management of the challenges posed by obsolescence in our operations.
              </li>
              <li>
              Proficient in crafting advanced programs utilizing Excel macros and VBA to streamline data manipulation and formatting processes.
               Skilled at automating tasks, significantly reducing time consumption, and eliminating potential human errors, thereby enhancing overall efficiency
              </li>
              <li>
              I have implemented an advanced digital obsolescence management system that seamlessly integrates all of our data sources, allowing for the generation 
              of predictive reports and to visualize the status of all our customer parts.
              </li>
            </ul>
          </div>

          <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-slate-600 w-full mb-2' >
            <h3 className='pt-4 font-bold underline'>Job Title: Electronics Components Engineer, REACH and Tinwhisker Analysis</h3>
              <ul className='mt-2 border-l-4 border-orange-200 p-4'>
              <li>Conducted thorough searches of electronic part numbers within the company's component system, ensuring accuracy and completeness.
              </li>
              <li>
                Streamlined Bills of Materials by reorganizing them into a predefined format for enhanced clarity and efficiency.
              </li>
              <li>
                Utilized the Company Eco analysis tool to systematically analyze Bills of Materials and generate comprehensive reports.
              </li>
              <li>
                Applied a defined process to analyze Eco data, identifying potential replacement parts when necessary.
              </li>
              <li>
                Assessed components on circuit boards to determine obsolescence status, termination finish, and source, collaborating closely with electronics design engineers to validate recommendations for replacement or re-termination based on TinWhisker analysis results.
              </li>
              <li>
                Employed a combination of in-house applications and external resources, demonstrating adaptability to varied tools and technologies.
              </li>
              <li>
                Developed and implemented Excel VBA/macro programs to automate repetitive tasks, significantly improving workflow efficiency.
              </li>
              <li>
                Organized and configured electronic components within the Product Data Management (PDM)/Windchill system, collaborating with configuration controllers and version control to ensure synchronization and error-free release of boards for production.
              </li>
              <li>
                Demonstrated proficiency in utilizing data from various systems to manage electronic components effectively.
              </li>
            
              </ul>
          </div>

          <div className='bg-gradient-to-r from-gray-900 via-gray-800 to-slate-600 w-full '>
          <h3 className='pt-4 font-bold underline'>Job Title: Tetra Mobile Communication System and Subscriber Engineer</h3>
          <p className='mt-2 border-l-4 border-orange-100 p-4'>
            I worked on tetra infrastructure and radio subscriber. My expertise extends to the development of baseline code plugs and fleet maps
            tailored to the unique needs of both new and existing customers. <br/><br/>
            
            A pivotal aspect of my role involved processing extensive vision alarm logs generated by Tetra mobile systems. Leveraging
            my proficiency in SQL databases, spreadsheets, and various software applications, I produced weekly reports that offered 
            invaluable insights into system performance. <br/><br/>

            Managing cryptographic tools on the KVL system and configuring end-to-end subscribers, I ensured the seamless operation of
            Prc and other crypto tools. Additionally, I played a vital role in resolving customer issues related to software, radio registration,
            authentication failures, and configurations. <br/><br/>
           
            In the capacity of system analysis and troubleshooting, I utilized the Scout/Airtracer system to scrutinize sites and system status,
            addressing challenges with precision. My commitment to staying at the forefront of technology was evident in my role, where I installed
            the latest software on computers and devices, promptly reporting any glitches to the development team. <br/><br/>

            Demonstrating a proactive approach, I conducted extensive tests on both hardware and software under development, encompassing feature,
            mobility, coverage, and drive tests. I also undertook conformance tests on the Airwave Ref system, ensuring the robustness and reliability 
            of emerging technologies. <br/><br/>
             
            Collaborating seamlessly with the development engineering team, I played a pivotal role in reproducing reported faults in both laboratory 
            and field environments. This hands-on experience allowed for a comprehensive understanding of emerging challenges and a proactive approach
            to rectifying them.
          </p>
          
          </div>
   

    </div>
  );
};

export default Homepage;
