// Navbar.js
import './App.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // If you're using React Router

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectlink_home, setSelectlink_home] = useState('border-4 border-amber-500');
  const [selectlink_hobby, setSelectlink_hobby] = useState('');
  const [selectlink_contact, setSelectlink_contact] = useState('');

  const toggleMenu = () => {
    setIsOpen(!isOpen);
      };

  const selectedlink = (e) => { 

    if(e==='home'){ setSelectlink_home('border-4 border-amber-500');setSelectlink_contact('');setSelectlink_hobby('');
    }else
    if(e==='hobby'){ setSelectlink_hobby('border-4 border-amber-500'); setSelectlink_home('');setSelectlink_contact('');
  }else if(e==='contact'){setSelectlink_contact('border-4 border-amber-500'); setSelectlink_hobby(''); setSelectlink_home('');}
   
  };

  return (
    
    
  <div className='relative '>

    <nav className="bg-gray-800 p-4">
      <div className=" mx-auto flex items-center  mt-0">
        
              {/* used for circuit video */}
            <video width="" height=""  loop autoPlay muted className='w-20 rotating-border'>
                <source src="/electric-circuit-animation.mp4" type="video/mp4" /> 
            </video>
        
         <strong className='text-white ml-auto mr-auto '><span className='mr-1 px-2'>Herbert</span> Ssevume <span className='mr-1 px-2'>Portfolio</span></strong>
           
           {/* used for log with rotating ages */}
         <div className='box ml-10  data'></div> 
        
         {/* used to create empty space to center layout*/}
         <div className='text-gray-800'>I'm hidden</div>
       
        <div
          className={`${
            isOpen ? 'hidden' : 'hidden'
          }  md:flex md:items-center md:w-auto `}
        >
          <div className="text-sm md:flex-grow ">
            <Link onClick={() =>selectedlink('home')}
              to="/"
              className={`block mt-4 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200 p-1 rounded ${selectlink_home}`}
            >
              Homepage
            </Link>
            <Link  onClick={() =>selectedlink('hobby')}
              to="/Hobby"
              className={`block mt-4 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200 p-1 rounded ${selectlink_hobby}`}
            >
              Tech-Projects
            </Link>
            <Link  onClick={() =>selectedlink('contact')}
              to="/contact"
              className={`block mt-4 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200 p-1 rounded ${selectlink_contact}`}
            >
              Contact_Info
            </Link>
          </div>
          </div>
      </div>
    </nav>

         {/* Hamburger menu for small screens */}
        <div className="bg-gray-500 md:hidden absolute right-3 top-1 rounded">
          <button
            onClick={toggleMenu}
            className="text-white focus:outline-none "
          >
            <svg
              className="h-6 w-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      {/* Navigation links for hamburger */}
      <div
          className={`${
            isOpen ? 'block' : 'hidden'
          }  md:hidden bg-gray-500/50 absolute right-0 top-9 rounded `}
        >
          <div className="text-sm md:flex-grow">
            <Link onClick={toggleMenu}
              to="/"
              className="block ml-2 mt-1 mr-1 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200  p-1 rounded"
            >
              Homepage
            </Link>
            <Link onClick={toggleMenu}
              to="/Hobby"
              className="block ml-2 mt-1 mr-1 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200  p-1 rounded"
            >
              Tech-Projects
            </Link>
            <Link onClick={toggleMenu}
              to="/contact"
              className="block ml-2 mt-1 mr-1 mb-1 md:inline-block md:mt-0 text-black hover:bg-orange-500 mr-4 bg-red-200  p-1 rounded"
            >
              Contact_info
            </Link>
          </div>
          </div>


  </div>
 

  );
};

export default Navbar;
