import React from 'react';
import './index.css';
import Navbar from './Navbar';
import Homepage from './Homepage';
import Contact from './Contact';
import Hobby from './Hobby';
import Footer from './Footer';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function App() {
  return (
    <Router >
    
      <div className="">
         <Navbar /> 
          <Switch>
            <Route path="/" exact component={Homepage} />
            <Route path="/contact" component={Contact} />
            <Route path="/hobby" component={Hobby} />
          </Switch>
          <Footer />
      </div>
    </Router>
  );
}

export default App;
