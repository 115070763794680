
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className='text-white flex items-center  px-5 py-2 mt-1 text-sm'>
      
            <div className='border-l pl-2 flex-1'>&copy; 2024 Developed by Herbert Ssevume. All rights reserved.</div>
              <div className='border-l pl-2 space-x-2 flex-1'>
                  <a href="mailto:h.sev@tiscali.co.uk" className='block'>h.sev@tiscali.co.uk </a>
                  <a href="/my-facebook-link"><FontAwesomeIcon icon={faFacebook} size="2x"/> </a>
                  <a href="/my-twitter-link"><FontAwesomeIcon icon={faTwitter} size="2x" /></a>
                  <a href="/my-youtube-link"><FontAwesomeIcon icon={faYoutube} size="2x" /></a>
              </div>    
    
    </footer>
  );
};

export default Footer;
