import React from 'react';

const Contact = () => {
  return (
    <div className="min-h-screen mx-4 text-white bg-black">
      <h2><strong>Contact</strong></h2>
      <p>Email:h.sev@tiscali.co.uk</p>
      <p>Location: United Kingdom</p>
    </div>
  );
};

export default Contact;
